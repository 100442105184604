import React from 'react';
import { getCookie } from 'cookies-next';
import cx from 'classnames';
import { CountryCode } from 'libphonenumber-js/max';
import {
  Field,
  FieldProps,
  Formik,
  FormikHelpers,
  FormikProps,
  FormikValues,
} from 'formik';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import {
  DATASOURCES_IDS,
  getDatasourceEntry,
} from '@/helpers/datasources/fetchDatasources';
import { getFieldData, getFieldLabels } from '@/helpers/form/formFields';
import {
  renderDuplicationError,
  renderMixedContentUsedError,
  renderRequiredFieldsMissingError,
} from '@/helpers/form/warningRenderers';
import {
  getAllRequiredDeprecatedFields,
  hasMixedContent,
} from '@/helpers/form/deprecated';
import {
  buildThankYouPageUrl,
  getAnalyticalId,
  getFieldsValidationSchema,
  getGoogleClientId,
  isRedirectForm,
  REQUIRED_FIELDS,
  getExistingFieldsIds,
  submitForm,
  getInitialValues,
  getFormUserType,
} from '@/helpers/form/formHelpers';
import { EVENTS } from '@/helpers/gtm/constants';
import { pushEventToDataLayer } from '@/helpers/gtm/gtmHelper';
import {
  GtmIdContext,
  ReferringFallback,
  SSGDataContext,
} from '@/helpers/contexts';
import FormStep from '../FormStep/FormStep';
import ButtonComponent from '../../components/Button/Button';
import EmailField from '../EmailField/EmailField';
import NameField from '../NameField/NameField';
import PhoneInput from '../PhoneNumberField/PhoneNumberField';
import FormSubmitButton from '../FormSubmitButton/FormSubmitButton';
import NewsletterCheckbox from '../NewsletterCheckbox/NewsletterCheckbox';
import TermsAndConditionsCheckbox from '../TermsAndConditionsCheckbox/TermsAndConditionsCheckbox';
import { FormBlokProps } from './FormType';
import FormStepper from '../FormStepper/FormStepper';
import { addInternalParamsToUrl, fillUrlValues } from '../../helpers/urlParams';
import CtaButton from '../CtaButton/CtaButton';
import { getStepFields } from './formFieldsHelpers';

export interface FormProps {
  blok: FormBlokProps;
}

// Declare GTM dataLayer array.
declare global {
  interface Window {
    dataLayer: object[];
    Cookiebot: Cookiebot;
  }
}

const Form = ({ blok }: FormProps) => {
  const gtmId = React.useContext(GtmIdContext);
  const referringFallback = React.useContext(ReferringFallback);
  const dataSourcesContext = React.useContext(SSGDataContext);
  const [formDatasources, setFormDatasources] = React.useState<
    Record<string, string>
  >(dataSourcesContext.formLabels || {});

  const [activeStep, setActiveStep] = React.useState(0);
  const [googleClientId, setGoogleClientId] = React.useState('none-set');
  const [analyticalId, setAnalyticalId] = React.useState('none-set');
  const [duplicatedFields, setDuplicatedFields] = React.useState<Array<string>>(
    [],
  );
  const [missingRequiredFields, setMissingRequiredFields] =
    React.useState<boolean>(false);
  const [referralId, setReferralId] = React.useState('');
  const [recommendedTutorId, setRecommendedTutorId] = React.useState('');
  const [fullLeadSubmitUrl, setFullLeadSubmitUrl] = React.useState('');
  const [fullReferringPageUrl, setFullReferringPageUrl] = React.useState('');
  const [customSessionId, setCustomSessionId] = React.useState('');

  const {
    addDataToSuccessUrl = false,
    backButtonLabel,
    ctaButton: [ctaButton] = [],
    fields = [],
    legalText: [legalText] = [],
    steps = [],
    stepper: [stepper] = [],
    submitButton: [submitButton] = [],
  } = blok;

  const isLastStep = steps.length === 0 || activeStep === steps.length - 1;

  const filteredFields = fields.filter((field) => !field.excluded);

  React.useEffect(() => {
    setAnalyticalId(getAnalyticalId());

    const getGoogleClientData = () => setGoogleClientId(getGoogleClientId());

    getGoogleClientData();
    window.addEventListener('CookiebotOnAccept', getGoogleClientData);
    window.addEventListener('CookiebotOnDecline', getGoogleClientData);

    return () => {
      window.removeEventListener('CookiebotOnAccept', getGoogleClientData);
      window.removeEventListener('CookiebotOnDecline', getGoogleClientData);
    };
  }, []);

  React.useEffect(() => {
    const fetchDatasources = async () => {
      const pageLang = document.documentElement.lang;
      const datasources = await getDatasourceEntry(
        DATASOURCES_IDS.FORMS,
        pageLang,
      );

      setFormDatasources(datasources);
    };
    fetchDatasources();

    if (window) {
      const urlObject = new URL(window.location.href);
      setReferralId(urlObject.searchParams.get('referral_id') || '');
      setRecommendedTutorId(
        urlObject.searchParams.get('recommended_tutor_id') || '',
      );
      setFullLeadSubmitUrl(urlObject.href);
    }

    setFullReferringPageUrl(
      getCookie('referring')?.toString() || referringFallback?.toString() || '',
    );

    setCustomSessionId(getCookie('_custom_sessionID')?.toString() || '');
  }, []);

  const existingFieldsIds = React.useMemo(
    () => getExistingFieldsIds(blok),
    [blok],
  );

  React.useEffect(() => {
    const duplicates = existingFieldsIds.filter(
      (item, index, arr) => arr.indexOf(item) !== index,
    );

    if (duplicates) {
      setDuplicatedFields(duplicates);
    }
  }, [blok, existingFieldsIds]);

  React.useEffect(() => {
    setMissingRequiredFields(
      !REQUIRED_FIELDS.every((fieldId) => existingFieldsIds.includes(fieldId)),
    );
  }, [blok, existingFieldsIds]);

  const initialValues = React.useMemo(
    () =>
      getInitialValues(blok, {
        referralID: referralId,
        lastSeen: fullLeadSubmitUrl,
        recommendedTutorID: recommendedTutorId,
        fullReferringPageUrl:
          getCookie('referring')?.toString() ||
          referringFallback?.toString() ||
          '',
        customSessionID: getCookie('_custom_sessionID')?.toString() || '',
      }),
    [filteredFields, steps, referralId, fullLeadSubmitUrl, recommendedTutorId],
  );

  const validationSchema = React.useMemo(() => {
    /* Deprecated */
    if (filteredFields && filteredFields?.length > 0) {
      return getFieldsValidationSchema(filteredFields, formDatasources);
    }

    const deprecatedFields = getAllRequiredDeprecatedFields(blok);

    if (deprecatedFields.length > 0) {
      const deprecatedFieldsSchema = getFieldsValidationSchema(
        deprecatedFields.map((field) => ({ ...field, required: true })),
        formDatasources,
      );
      return deprecatedFieldsSchema;
    }
    /* Deprecated */

    const stepFields = getStepFields(steps[activeStep]).filter(
      (field) => !field.excluded,
    );

    return getFieldsValidationSchema(stepFields, formDatasources);
  }, [steps, activeStep, formDatasources, filteredFields]);

  const isTutorForm = React.useMemo(
    () => getFormUserType(blok) === 'Tutor',
    [blok],
  );

  const handleSubmitForm = async (values: FormikValues) => {
    try {
      const result = await submitForm({
        ...values,
        analyticalId,
        googleClientId,
      });

      await pushEventToDataLayer(EVENTS.FORM_SUBMISSION_SUCCESS, {
        userEmail: values.email,
        userPhone: values.phone,
      });

      if (window.optRedirectUrl) {
        let redirectUrl = window.optRedirectUrl;

        const { token } = await result.json();
        redirectUrl = addInternalParamsToUrl(
          fillUrlValues(redirectUrl, values),
          {
            alwaysIncludeToken: true,
            formikValues: values,
            gtmId,
            referring: getCookie('referring') || referringFallback,
            token,
          },
        );

        return await new Promise((resolve) =>
          setTimeout(() => {
            window.location.assign(redirectUrl);
            resolve(true);
          }, 2000),
        );
      }

      if (blok.successPage?.url || blok.successPage?.cached_url) {
        let redirectUrl = await buildThankYouPageUrl(blok.successPage);

        if (addDataToSuccessUrl) {
          const { token } = await result.json();
          redirectUrl = addInternalParamsToUrl(
            fillUrlValues(redirectUrl, values),
            {
              alwaysIncludeToken: true,
              formikValues: values,
              gtmId,
              referring: getCookie('referring') || referringFallback,
              token,
            },
          );
        }

        return await new Promise((resolve) =>
          setTimeout(() => {
            window.location.assign(redirectUrl);
            resolve(true);
          }, 2000),
        );
      }

      if (blok.thankYouPage?.url || blok.thankYouPage?.cached_url) {
        const redirectUrl = await buildThankYouPageUrl(blok.thankYouPage);

        return await new Promise((resolve) =>
          setTimeout(() => {
            window.location.assign(redirectUrl);
            resolve(true);
          }, 2000),
        );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleStepBack = () => setActiveStep(activeStep - 1);

  const handleFormikSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>,
  ) => {
    if (isLastStep) {
      return handleSubmitForm(values);
    }

    setActiveStep(activeStep + 1);
    formikHelpers.setTouched({});
    formikHelpers.setSubmitting(false);
  };

  return (
    <div
      className={cx('flex flex-col', {
        'gap-2 lg:gap-3': !isTutorForm,
        'gap-9': isTutorForm && activeStep > 0,
        'gap-12': isTutorForm && activeStep === 0,
      })}
    >
      {hasMixedContent(blok) && renderMixedContentUsedError()}
      {duplicatedFields.length > 0 && renderDuplicationError(duplicatedFields)}
      {missingRequiredFields &&
        !isRedirectForm(blok) &&
        renderRequiredFieldsMissingError(existingFieldsIds)}
      <div className="flex flex-col gap-2 lg:gap-3">
        {stepper && steps.length && (
          <FormStepper activeStep={activeStep} blok={stepper} steps={steps} />
        )}
        {activeStep > 0 && (
          <div className="flex">
            <div>
              <ButtonComponent
                label={backButtonLabel}
                hasLTicon
                type="textLink"
                testId={`CtaLink-${blok._uid}`}
                onClick={handleStepBack}
              />
            </div>
          </div>
        )}
      </div>

      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleFormikSubmit}
        validationSchema={validationSchema}
      >
        {(formikProps: FormikProps<FormikValues>) => (
          <form
            {...storyblokEditable(blok)}
            aria-label="form"
            className="flex flex-col gap-2"
            onSubmit={formikProps.handleSubmit}
          >
            {/* Deprecated */}
            {!!blok.firstName.length && (
              <Field name="firstName" disabled={formikProps.isSubmitting}>
                {({ field }: FieldProps) => (
                  <NameField
                    field={field}
                    blok={{
                      ...blok.firstName[0],
                      nameLabel: formDatasources.name_label,
                      namePlaceholder: formDatasources.name_placeholder,
                      formikErrors: formikProps.errors,
                      touched: formikProps.touched,
                    }}
                  />
                )}
              </Field>
            )}
            {!!blok.email.length && (
              <Field name="email" disabled={formikProps.isSubmitting}>
                {({ field }: FieldProps) => (
                  <EmailField
                    field={field}
                    blok={{
                      ...blok.email[0],
                      emailLabel: formDatasources.email_label,
                      emailPlaceholder: formDatasources.email_placeholder,
                      formikErrors: formikProps.errors,
                      touched: formikProps.errors,
                    }}
                  />
                )}
              </Field>
            )}
            {!!blok.phone.length && (
              <Field name="phone" disabled={formikProps.isSubmitting}>
                {({ field }: FieldProps) => (
                  <PhoneInput
                    field={field}
                    blok={{
                      ...blok.phone[0],
                      label: formDatasources.phone_label,
                      placeholder: formDatasources.phone_placeholder,
                      defaultCountry: (blok.countryCode as CountryCode) || 'DE',
                      onChange: formikProps.handleChange('phone'),
                      formikErrors: formikProps.errors,
                      touched: formikProps.touched,
                      labels: getFieldLabels(formDatasources, 'phone'),
                    }}
                  />
                )}
              </Field>
            )}
            {filteredFields?.map((nestedBlok) => (
              <Field
                name={getFieldData(nestedBlok.component).id}
                key={nestedBlok._uid}
                disabled={formikProps.isSubmitting}
              >
                {(props: FieldProps) =>
                  nestedBlok.hidden ? (
                    <>
                      <label
                        className="hidden"
                        htmlFor={getFieldData(nestedBlok.component).id}
                      >
                        {getFieldData(nestedBlok.component).hubspotId}
                      </label>
                      <Field name={getFieldData(nestedBlok.component).id}>
                        {({ field }: FieldProps) => (
                          <input
                            {...field}
                            className="hidden"
                            id={getFieldData(nestedBlok.component).id}
                          />
                        )}
                      </Field>
                    </>
                  ) : (
                    <StoryblokComponent
                      blok={{
                        ...nestedBlok,
                        label:
                          nestedBlok.label ||
                          formDatasources[`${nestedBlok.component}_label`],
                        labels: getFieldLabels(
                          formDatasources,
                          getFieldData(nestedBlok.component).id,
                        ),
                        placeholder:
                          nestedBlok.placeholder ||
                          formDatasources[
                            `${nestedBlok.component}_placeholder`
                          ],
                        formikErrors: formikProps.errors,
                        touched: formikProps.touched,
                        formCountry: (blok.countryCode as CountryCode) || 'DE',
                        formikProps,
                      }}
                      {...props}
                    />
                  )
                }
              </Field>
            ))}
            {!!blok.newsletter.length && (
              <Field name="emailConsentNewsletter">
                {({ field }: FieldProps) => (
                  <NewsletterCheckbox
                    field={field}
                    blok={{
                      ...blok.newsletter[0],
                      touched: formikProps.touched,
                    }}
                  />
                )}
              </Field>
            )}
            {!!blok.termsAndConditions.length && (
              <Field name="termsAndConditions">
                {({ field }: FieldProps) => (
                  <TermsAndConditionsCheckbox
                    field={field}
                    blok={{
                      ...blok.termsAndConditions[0],
                      formikErrors: formikProps.errors,
                      touched: formikProps.touched,
                    }}
                  />
                )}
              </Field>
            )}
            {/* Deprecated END */}

            <Field name="experiment">
              {({ field }: FieldProps) => (
                <>
                  <label className="hidden" htmlFor="experiment">
                    Experiment
                  </label>
                  <input {...field} className="hidden" id="experiment" />
                </>
              )}
            </Field>
            <Field name="reachedOutVia">
              {({ field }: FieldProps) => (
                <>
                  <label className="hidden" htmlFor="reachOutVia">
                    Reached Out Via
                  </label>
                  <input {...field} className="hidden" id="reachOutVia" />
                </>
              )}
            </Field>
            {!existingFieldsIds.includes('userType') && (
              <Field name="userType">
                {({ field }: FieldProps) => (
                  <>
                    <label className="hidden" htmlFor="userType">
                      User Type
                    </label>
                    <input {...field} className="hidden" id="userType" />
                  </>
                )}
              </Field>
            )}
            <Field name="language">
              {({ field }: FieldProps) => (
                <>
                  <label className="hidden" htmlFor="language">
                    Language
                  </label>
                  <input {...field} className="hidden" id="language" />
                </>
              )}
            </Field>
            {referralId && (
              <Field name="referralID">
                {({ field }: FieldProps) => (
                  <>
                    <label className="hidden" htmlFor="referralID">
                      ReferralId
                    </label>
                    <input
                      {...field}
                      value={referralId}
                      className="hidden"
                      id="referralID"
                    />
                  </>
                )}
              </Field>
            )}
            {recommendedTutorId && (
              <Field name="recommendedTutorID">
                {({ field }: FieldProps) => (
                  <>
                    <label className="hidden" htmlFor="recommendedTutorID">
                      RecommendedTutorId
                    </label>
                    <input
                      {...field}
                      value={recommendedTutorId}
                      className="hidden"
                      id="recommendedTutorID"
                    />
                  </>
                )}
              </Field>
            )}
            <Field name="lastSeen">
              {({ field }: FieldProps) => (
                <>
                  <label className="hidden" htmlFor="fullLeadSubmitUrl">
                    Full Lead Submit URL
                  </label>
                  <input
                    {...field}
                    value={fullLeadSubmitUrl}
                    className="hidden"
                    id="fullLeadSubmitUrl"
                  />
                </>
              )}
            </Field>
            <Field name="fullReferringPageUrl">
              {({ field }: FieldProps) => (
                <>
                  <label className="hidden" htmlFor="fullReferringPageUrl">
                    Full Referring Page URL
                  </label>
                  <input
                    {...field}
                    value={fullReferringPageUrl}
                    className="hidden"
                    id="fullReferringPageUrl"
                  />
                </>
              )}
            </Field>
            <Field name="customSessionID">
              {({ field }: FieldProps) => (
                <>
                  <label className="hidden" htmlFor="customSessionID">
                    custom_session_id
                  </label>
                  <input
                    {...field}
                    value={customSessionId}
                    className="hidden"
                    id="customSessionID"
                  />
                </>
              )}
            </Field>

            {steps.map((nestedBlok, index) => (
              <FormStep
                activeStep={activeStep}
                blok={nestedBlok}
                className={cx({ hidden: activeStep !== index })}
                formCountry={(blok.countryCode as CountryCode) || 'DE'}
                formikProps={formikProps}
                isLastStep={isLastStep}
                key={nestedBlok._uid}
                labels={formDatasources}
                isTutorForm={isTutorForm}
              />
            ))}

            {legalText && <StoryblokComponent blok={legalText} />}

            {/* Deprecated */}
            {submitButton && (
              <div className="w-full pt-2">
                <FormSubmitButton
                  blok={submitButton}
                  disabled={formikProps.isSubmitting}
                  loading={formikProps.isSubmitting}
                />
              </div>
            )}
            {/* Deprecated END */}

            {ctaButton && <CtaButton blok={ctaButton} />}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Form;
